import axios, { AxiosResponse } from "axios";
import { API_SERVER_URL } from "../utils/const";
import { Auth } from "./AuthService";

interface GetControllersResponse extends ServerResponse {
  _embedded?: {
    liftCntlrs: Controller[];
  };
}

interface GetControllerCodesResponse extends ServerResponse {
  _embedded?: {
    liftCntlrCodes: ControllerCode[];
  };
}

interface GetControllerDiagnosisResponse extends ServerResponse {
  _embedded?: {
    resultLiftCntlrs: ControllerDiagnosis[];
  };
}

interface GetControllerStatusRequest {
  page?: number | string;
  size?: number | string;
  liftId: number | string;
}

export default class ControllerService {
  private static PATH = "api/admin";

  private static CONTROLLER = "liftCntlrs";

  private static CONTROLLER_CODE = "liftCntlrCodes";

  private static RESULT_CONTROLLER = "resultLiftCntlrs";

  private static pathSetter(auth: Auth) {
    if (auth.auth !== "A") ControllerService.PATH = "api";
    else ControllerService.PATH = "api/admin";
    return auth.id_token;
  }

  public static async getControllers(auth: Auth): Promise<GetControllersResponse> {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const token = ControllerService.pathSetter(auth);
          const response: AxiosResponse<GetControllersResponse> = await axios({
            method: "GET",
            url: `${API_SERVER_URL}/${ControllerService.PATH}/${ControllerService.CONTROLLER}`,
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          resolve(response.data);
        } catch (err) {
          reject(err);
        }
      })();
    });
  }

  public static async addController(mfCpnyNm: string, id: string, auth: Auth): Promise<void> {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const token = ControllerService.pathSetter(auth);
          const response: AxiosResponse<void> = await axios({
            method: "POST",
            url: `${API_SERVER_URL}/${ControllerService.PATH}/${ControllerService.CONTROLLER}`,
            headers: {
              Authorization: `Bearer ${token}`,
            },
            data: {
              mfCpnyNm,
              id,
            },
          });
          resolve(response.data);
        } catch (err) {
          reject(err);
        }
      })();
    });
  }

  public static async editController(id: string, mfCpnyNm: string, auth: Auth): Promise<void> {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const token = ControllerService.pathSetter(auth);
          const response: AxiosResponse<any> = await axios({
            method: "PATCH",
            url: `${API_SERVER_URL}/${ControllerService.PATH}/${ControllerService.CONTROLLER}/${id}`,
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-type": "application/merge-patch+json",
            },
            data: {
              id,
              mfCpnyNm,
            },
          });

          if (response.data.message) {
            throw new Error(response.data.message);
          }

          resolve(response.data);
        } catch (err) {
          reject(err);
        }
      })();
    });
  }

  public static async removeController(id: string, auth: Auth): Promise<void> {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const token = ControllerService.pathSetter(auth);
          const response: AxiosResponse<any> = await axios({
            method: "DELETE",
            url: `${API_SERVER_URL}/${ControllerService.PATH}/${ControllerService.CONTROLLER}/${id}`,
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });

          if (response.data.message) {
            throw new Error(response.data.message);
          }

          resolve(response.data);
        } catch (err) {
          reject(err);
        }
      })();
    });
  }

  public static async getControllerCodes(
    id: string,
    auth: Auth,
  ): Promise<GetControllerCodesResponse> {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const token = ControllerService.pathSetter(auth);
          const response: AxiosResponse<GetControllerCodesResponse> = await axios({
            method: "GET",
            url: `${API_SERVER_URL}/${ControllerService.PATH}/${ControllerService.CONTROLLER_CODE}`,
            headers: {
              Authorization: `Bearer ${token}`,
            },
            params: {
              "liftCntlr.id": id,
            },
          });
          resolve(response.data);
        } catch (err) {
          reject(err);
        }
      })();
    });
  }

  public static async addControllerCode(
    id: string,
    rawCd: string,
    statusMsg: string,
    usrDfnCd: string,
    auth: Auth,
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const token = ControllerService.pathSetter(auth);
          const response: AxiosResponse<void> = await axios({
            method: "POST",
            url: `${API_SERVER_URL}/${ControllerService.PATH}/${ControllerService.CONTROLLER_CODE}`,
            headers: {
              Authorization: `Bearer ${token}`,
            },
            data: {
              liftCntlr: {
                id,
              },
              rawCd,
              usrDfnCd,
              statusMsg,
            },
          });
          resolve(response.data);
        } catch (err) {
          reject(err);
        }
      })();
    });
  }

  public static async editControllerCode(
    id: string,
    codeId: number,
    rawCd: string,
    statusMsg: string,
    usrDfnCd: string,
    auth: Auth,
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const token = ControllerService.pathSetter(auth);
          const response: AxiosResponse<any> = await axios({
            method: "PATCH",
            url: `${API_SERVER_URL}/${ControllerService.PATH}/${ControllerService.CONTROLLER_CODE}/${codeId}`,
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-type": "application/merge-patch+json",
            },
            data: {
              id: codeId,
              liftCntlr: {
                id,
              },
              rawCd,
              statusMsg,
              usrDfnCd,
            },
          });

          if (response.data.message) {
            throw new Error(response.data.message);
          }
          resolve(response.data);
        } catch (err) {
          reject(err);
        }
      })();
    });
  }

  public static async removeControllerCode(id: number, auth: Auth): Promise<void> {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const token = ControllerService.pathSetter(auth);
          const response: AxiosResponse<any> = await axios({
            method: "DELETE",
            url: `${API_SERVER_URL}/${ControllerService.PATH}/${ControllerService.CONTROLLER_CODE}/${id}`,
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });

          if (response.data.message) {
            throw new Error(response.data.message);
          }
          resolve(response.data);
        } catch (err) {
          reject(err);
        }
      })();
    });
  }

  public static async getControllerStatus(
    { liftId, page, size }: GetControllerStatusRequest,
    auth: Auth,
  ): Promise<GetControllerDiagnosisResponse> {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const token = ControllerService.pathSetter(auth);
          const response: AxiosResponse<GetControllerDiagnosisResponse> = await axios({
            method: "GET",
            url: `${API_SERVER_URL}/${ControllerService.PATH}/${ControllerService.RESULT_CONTROLLER}`,
            headers: {
              Authorization: `Bearer ${token}`,
            },
            params: {
              "lift.id": liftId,
              sort: "checkedAt,desc",
              size,
              page,
            },
          });
          resolve(response.data);
        } catch (err) {
          reject(err);
        }
      })();
    });
  }

  public static async getResult(
    id: number | string,
    auth: Auth,
  ): Promise<GetControllerDiagnosisResponse> {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const token = ControllerService.pathSetter(auth);
          const response: AxiosResponse<GetControllerDiagnosisResponse> = await axios({
            method: "GET",
            url: `${API_SERVER_URL}/${ControllerService.PATH}/${ControllerService.RESULT_CONTROLLER}/${id}`,
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          resolve(response.data);
        } catch (err) {
          reject(err);
        }
      })();
    });
  }
}
