import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import ko from "./ko.json";
import en from "./en.json";
import ch from "./ch.json";
import { LANG } from "../utils/const";

i18n.use(initReactI18next).init({
  resources: {
    ko: {
      translation: ko,
    },
    en: {
      translation: en,
    },
    ch: {
      translation: ch,
    },
  },
  lng: LANG,
  fallbackLng: LANG,
  // {
  // default: ["ko-KR"],
  // "zh-CH-": ["zh-CH"],
  // "en-US": ["en-US"],
  // },
  debug: false,
  defaultNS: "translation",
  ns: "translation",
  keySeparator: false,
  interpolation: {
    escapeValue: false,
  },
  react: {
    useSuspense: false,
  },
});

export default i18n;
